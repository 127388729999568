import { createApp, reactive } from 'vue';
import VueGtag from 'vue-gtag';
import apiClient from '@/apiClient';
import router from '@/plugins/router';

import App from '@/App.vue';

/* Global style */
import '../css/styles.css';

const app = createApp(App)
  .use(router)
  .use(VueGtag, {
    config: {
      id: 'G-YHV1QYNC4F',
      params: {
        anonymize_ip: true,
      },
    },
    appName: 'RRS CIT',
    pageTrackerScreenviewEnabled: true,
    pageTrackerExcludedRoutes: [
      'login',
      'admin-login',
      'password',
      'password-reset',
      'admin',
    ],
  }, router);

const globals = app.config.globalProperties;
globals.apiClient = apiClient;
globals.settings = {
  roles: reactive([]),
  origins: reactive([]),
  defects: reactive([]),
};

router.isReady().then(() => {
  app.mount('#app');
});
