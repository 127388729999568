import axios from 'axios';
import store from '@/store';
import router from '@/plugins/router';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response
      && [401, 419].includes(error.response.status)
    ) {
      store.auth.user = null;
      if (router.currentRoute.value.meta.auth) {
        return router.push({ name: 'login' });
      }
    }
    return Promise.reject(error);
  },
);

export default apiClient;
