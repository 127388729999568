/* eslint-disable max-len */
import apiClient from '@/apiClient';

export default [
  {
    path: '/',
    redirect(to) {
      if (to.query.t && !to.query.signature) {
        (async () => {
          const response = await apiClient.get('report/token', {
            params: {
              token: to.query.t,
            },
          });
          window.location.href = response.data.url;
        })();
      }

      return 'dashboard';
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: { auth: false },
  },
  {
    name: 'admin-login',
    path: '/admin',
    component: () => import('@/views/AdminLogin.vue'),
    meta: { auth: false },
  },
  {
    name: 'password',
    path: '/wachtwoord',
    component: () => import('@/views/Password.vue'),
    meta: { auth: false },
  },
  {
    name: 'password-reset',
    path: '/wachtwoord-reset/:token/:email',
    component: () => import('@/views/PasswordReset.vue'),
    props: (route) => ({ token: route.params.token, email: route.params.email }),
    meta: { auth: false },
  },
  {
    name: 'report',
    path: '/rapportage',
    component: () => import('@/views/Report.vue'),
    props: (route) => ({
      id: route.query.inspection ? +route.query.inspection : null,
      taskNumber: route.query.t ? +route.query.t : null,
      signature: route.query.signature,
    }),
    meta: { auth: false },
    beforeEnter(to) { // eslint-disable-line consistent-return
      if (!to.query.signature) {
        return { name: 'not-found' };
      }
    },
  },
  {
    name: 'admin',
    path: '/',
    component: () => import('@/views/Admin.vue'),
    meta: { auth: true },
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { search: true },
      },
      {
        name: 'inspections',
        path: '/camera-inspecties',
        component: () => import('@/views/CameraInspections.vue'),
        meta: { search: true },
      },
      {
        name: 'inspections-add',
        path: '/camera-inspecties/toevoegen',
        component: () => import('@/views/InspectionAdd.vue'),
        meta: { search: true },
      },
      {
        name: 'inspection',
        path: '/camera-inspecties/:inspection',
        component: () => import('@/views/InspectionEdit.vue'),
        props: (route) => ({
          id: +route.params.inspection,
        }),
        redirect: { name: 'inspection-data' },
        children: [
          {
            name: 'inspection-data',
            path: 'gegevens',
            component: () => import('@/views/InspectionData.vue'),
          },
          {
            name: 'inspection-files',
            path: 'bestanden',
            component: () => import('@/views/InspectionFiles.vue'),
          },
          {
            name: 'inspection-pipes',
            path: 'leidingen',
            component: () => import('@/views/InspectionPipes.vue'),
            meta: { search: true },
          },
          {
            name: 'inspection-email',
            path: 'email',
            component: () => import('@/views/InspectionEmail.vue'),
          },
          {
            name: 'inspection-quotation',
            path: 'offerte',
            component: () => import('@/views/InspectionQuotation.vue'),
          },
        ],
      },
      {
        name: 'users',
        path: '/gebruikers',
        component: () => import('@/views/Users.vue'),
        meta: { auth: true, search: true },
      },
      {
        name: 'management',
        path: '/beheer',
        component: () => import('@/views/Management.vue'),
        meta: { auth: true },
        redirect: { name: 'management-settings' },
        children: [
          {
            path: 'stamgegevens',
            name: 'management-settings',
            component: () => import('@/views/Settings.vue'),
          },
          {
            path: 'vestigingen',
            name: 'management-establishments',
            component: () => import('@/views/Establishment.vue'),
            meta: { search: true },
          },
          {
            path: 'rollen',
            name: 'management-roles',
            component: () => import('@/views/UserRoles.vue'),
            meta: { search: true },
          },
        ],
      },
      {
        name: 'log',
        path: '/log',
        component: () => import('@/views/Log.vue'),
        meta: { search: true },
      },
    ],
  },
  {
    name: 'forbidden',
    path: '/403',
    component: () => import('@/views/Forbidden.vue'),
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue'),
  },
];
